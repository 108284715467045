@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	background-color: black;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.glow-yellow,
.glow-hover-yellow:hover {
	/* transition: all 0.25s ease-in-out; */
	box-shadow: 0 0 10px 0px #ffffff33, 0 0 50px 0px #fbbf2460,
		inset #ffffff20 0px 0px 10px 0px, inset #fbbf2425 0px 0px 50px 0px;
}

.glow-hover-yellow-secondary:hover {
	/* transition: all 0.25s ease-in-out; */
	box-shadow: 0 0 10px 0px #ffffff33, 0 0 50px 0px #fbbf2410,
		inset #ffffff20 0px 0px 10px 0px, inset #fbbf2425 0px 0px 50px 0px;
}

.glow-green,
.glow-hover-green:hover {
	box-shadow: 0 0 10px 0px #ffffff33, 0 0 50px 0px #10b98190,
		inset #ffffff20 0px 0px 10px 0px, inset #10b98125 0px 0px 50px 0px;
}

.glow-blue,
.glow-hover-blue:hover {
	box-shadow: 0 0 10px 0px #ffffff33, 0 0 50px 0px #1d4fd890,
		inset #ffffff20 0px 0px 10px 0px, inset #1d4fd825 0px 0px 50px 0px;
}

.brighten,
.hover-brighten:hover {
	filter: brightness(125%);
	box-shadow: 0 0 10px 0px #ffffff33, inset #1d4fd825 0px 0px 100px 0px;
}

.text-outline {
	text-shadow: -1px -1px 0 #00000010, 1px -1px 0 #00000010, -1px 1px 0 #00000010,
		1px 1px 0 #00000010;
}

table {
	box-shadow: inset #ffffff20 0px 0px 10px 0px;
}

.borrow-limit::before {
	content: '';
	background-color: black;
	position: absolute;
	top: -6px;
	z-index: -1;
	left: 50%;
	transform: translateX(-50%);
	width: 110%;
	height: 110%;
	border-top-left-radius: 100%;
	border-top-right-radius: 100%;
}

.card-blur {
	position: relative;
	overflow: hidden;
	box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
}
.card-blur:before {
	backdrop-filter: blur(5px);
	content: '';
	height: 100%;
	position: absolute;
	width: 100%;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.3);
}

.dark-overlay {
	position: relative;
}

.dark-overlay:before {
	content: '';
	background: black;
	/* background-image: linear-gradient(red, yellow); */
	height: 100%;
	width: 100%;
	position: absolute;
	opacity: 0.2;
	top: 0;
}
